<template>
  <div
    class="fixed inset-0 z-50 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    aria-hidden="true"
    v-if="show"
    @click.prevent="closeRequest"
  >
    <div
      class="flex items-center justify-center w-full h-full min-h-screen text-center sm:block"
    >
      <div
        class="fixed inset-0 transition-opacity bg-[#101010] bg-opacity-75"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        v-on:click.stop
        class="relative inline-block overflow-x-hidden overflow-y-auto text-left align-middle transform border rounded-lg shadow-xl border-neutral-700 bg-neutral-900"
        :class="modalSize"
      >
        <template v-if="component">
          <component :is="component" v-bind="props" v-on:close="close" />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'overlay-container',

  data() {
    return {
      show: false,
      component: null,
      props: null,
      size: null,
      dismissable: true
    }
  },

  mounted() {
    this.listenForEvents()
  },

  computed: {
    modalSize() {
      let size = null
      switch (this.props?.type) {
        case 'action':
          size = 'sm:max-w-lg w-full sm:my-8 mx-4 mt-4 mb-20 sm:m-0'
          break

        case 'content':
          size =
            'lg:max-w-6xl w-full sm:my-8 mx-4 mt-4 mb-20 sm:m-0 min-h-[50%] max-h-[90vh]'
          break

        case 'fullview':
          size = 'lg:max-w-6xl w-full sm:my-8 mx-4 mt-4 mb-20 sm:m-0 h-[90vh]'
          break
      }

      // Fallback for modals that don't / can't rely on the props being passed
      if (!size) {
        const baseClass =
          'w-full my-0 overflow-y-auto text-gray-100 rounded-lg shadow sm:mt-16 md:mt-20'
        switch (this.size) {
          case 'sm':
            size = `${baseClass} max-w-xl`
            break
          case 'md':
            size = `${baseClass} max-w-4xl`
            break
          case 'full':
            size = `${baseClass} mobile:max-w-5xl`
            break
          case 'lg':
            size = `${baseClass} h-full mobile:max-w-5xl`
            break
          default:
            size = 'sm:max-w-lg w-full sm:my-8 mx-4 mt-4 mb-20 sm:m-0'
        }
      }

      return size
    }
  },

  methods: {

    closeRequest () {
      if(this.dismissable) this.close()
    },

    close() {
      this.show = false
      this.component = null
      this.props = null
    },

    listenForEvents() {
      this.$bus.$on('keyboard:event:escape', (event) => {
        this.closeRequest()
      })

      this.$bus.$on('overlay:modal', (event) => {
        this.showModal(event)
      })

      this.$bus.$on('overlay:modal:close', (event) => {
        this.close()
      })
    },

    reset() {
      this.component = null
      this.props = null
      this.show = false
      this.size = null
      this.dismissable = true
    },

    showModal(event) {
      this.reset()

      if (event.component !== undefined) {
        this.component = event.component

        if (event.dismissable !== undefined && event.dismissable === false) {
          this.dismissable = false
        }
        
        if (event.props !== undefined) {
          this.props = event.props
        }

        if (event.size !== undefined) {
          this.size = event.size
        }

        this.show = true
      }
    }
  }
}
</script>
